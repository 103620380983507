<template>
    <h1>单位管理</h1>
</template>

<script>
    export default {
        name: "unit"
    }
</script>

<style scoped>

</style>
